import React from "react";
import "./Hero.css";
import Me from "../../images/Me.JPG";
const Hero = () => {
  return (
    <section className="hero-container">
      <div className="hero-content">
        <h2 id="home">Developing Software That Create an Impact</h2>
        <p>
          Passionate Fullstack Developer | Transforming Ideas into easy to use
          and Visually Stunning Web and Mobile Software Solutions.
        </p>
      </div>
      <div className="hero-img">
        <div>
          <img className="my-pic" src={Me}  width={380} height={380}/>
        </div>
      </div>
    </section>
  );
};

export default Hero;
