import React,{useState} from "react";
import "./Navbar.css";
import MobileNavBar from "./MobileNavBar/MobileNavBar";
import cv from '../../cv/Isaac_Mulenga_Cv.pdf'

const Navbar = () => {
    
    const [openMenu, setOpenMenu] = useState(false)

    const toggleMenu = () =>{
        setOpenMenu(!openMenu);
    }
    const downLoad = () =>{
      fetch(cv)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Isaac_Mulenga_cv.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.error('Error:', error));
    }
  return (
    <>
        <MobileNavBar isOpen={openMenu} toggleMenu={toggleMenu}/>
      <nav className="nav-wrapper">
        <div className="nav-content">
          <span><a href="#">Isaacodes</a></span>

          <ul>
            <li>
              <a href="#" className="menu-item">Home</a>
            </li>
            <li>
              <a href="#skills" className="menu-item" >Skills</a>
            </li>
            <li>
              <a href="#experience" className="menu-item">Work Experience</a>
            </li>
            <li>
              <a href="#contact" className="menu-item">Contact Me</a>
            </li>
            <button className="contact-btn" onClick={downLoad}>
              Download Cv
            </button>
          </ul>
          <button className="menu-btn" onClick={toggleMenu}>
            <span 
            className={"material-symbols-outlined"}
            style={{fontSize: "1.8rem"}}
            >
                {openMenu ? "close" : "menu"}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
