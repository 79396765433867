import React from 'react'
import './SkillCard.css'

const SkillCard = ({title, iconUrl, isActive, onClick}) => {
  
  return (
   <div 
    className={`skills-card ${isActive ? "active":""}`}
    onClick={()=>onClick()}
   >
    {/* <div className="skill-icon">
        <img src={iconUrl} width={60} height={60} />
    </div> */}
    <span>{title}</span>
   </div>
  )
}

export default SkillCard