import React from "react";
import './footer.css';

export const Footer = () => {
    
  return (
    <>
      <div className="container">
        <p>Isaacodes &copy;2024</p>
      </div>
    </>
  );
};
