import React from "react";
import "./MobileNavBar.css";
import cv from '../../../cv/Isaac_Mulenga_Cv.pdf';

const MobileNavBar = ({ isOpen, toggleMenu }) => {
  const downLoad = () =>{
    fetch(cv)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Isaac_Mulenga_cv.pdf');
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => console.error('Error:', error));
  }
  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <ul>
            <li>
              <a href="#" className="menu-item">
                Home
              </a>
            </li>
            <li>
              <a href="#skills" className="menu-item">
                Skills
              </a>
            </li>
            <li>
              <a href="#experience" className="menu-item">
                Work Experience
              </a>
            </li>
            <li>
              <a href="#contact" className="menu-item">
                Contact Me
              </a>
            </li>
            <button className="contact-btn" onClick={downLoad}>
              Download Cv
            </button>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNavBar;
