import React from 'react'
import './ContactInfoCard.css'
const ContactInfoCard = ({iconImage,text}) => {
    return (
    
    <div className='contact-details-card'>
        <div className='icon'>
            <img src={iconImage} alt='Contact icon'/>
        </div>
        <a href={text}>{text}</a>
    </div>
  )
}

export default ContactInfoCard