import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import Swal from "sweetalert2"; 
import './ContactForm.css'
const ContactForm = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_e27tqsi', 'template_59yka9j', form.current, {
        publicKey: 'Lr1URp24e6je1QRvB',
      })
      .then(
        () => {
          callAlertSuccess();
        },
        (error) => {
          callAlertSError();
        },
      );
  };

  const callAlertSuccess = () =>{
    Swal.fire({  
      icon: 'success', 
      title: 'Success',  
      type: 'success',  
      text: 'Your Email Has Been Sent.',  
    }).then(function (){
      window.location.reload();
    })  
    
  }

  const callAlertSError = () =>{
    Swal.fire({  
      icon: 'error', 
      title: 'Error',  
      type: 'error',  
      text: 'Your Email Was Not Sent.',  
    });  
  }

  return (
    <div className='contact-form-content'>
        <form action="" ref={form} onSubmit={sendEmail}>
            <div className='name-container'>
                <input type="text" name="firstName" id="FirstName" placeholder='First Name' required />
                <input type="text" name="secondName" id="SecondName" placeholder='Second Name' required/>
            </div>
            <input type="email" name='email' placeholder='Email' required/>
            <textarea name="message" id="message" placeholder='Message' required></textarea>
            <button type="submit">Send</button>
        </form>
    </div>
  )
}

export default ContactForm