import React from "react";

import "./ContactMe.css";
import ContactInfoCard from "./ContactInfoCard/ContactInfoCard";
import ContactForm from "./ContactForm/ContactForm";
import email from '../../images/email.jpg'
import linkedIn from '../../images/linkedIn.png'
import git from '../../images/git.png'
const ContactMe = () => {
  return (
    <section className="contact-container">
      <h5 id="contact">Contact Me</h5>
      <div className="contact-content">
        <div style={{ flex: 1 }}>
          <ContactInfoCard
          iconImage={linkedIn}
            text="https://www.linkedin.com/in/isaac-mulenga-67b993206/"
          />
          <ContactInfoCard
            iconImage={git}
            text="https://github.com/Isaacodes-dev"
          />
        </div>

        <div style={{ flex: 1 }}>
            <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactMe;
