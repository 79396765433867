import React, { useRef } from 'react'
import './WorkExperience.css'
import ExperienceCard from './ExperienceCard/ExperienceCard'
import { WORK_EXPERIENCE } from '../utils/data'
import Slider from 'react-slick'

const WorkExperience = () => {
    const slideRef = useRef()

    const slideRight = () =>{
        slideRef.current.slickNext();
    }

    const slideLeft = () =>{
        slideRef.current.slickPrev();
    }

    const setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive:[
            {
                breakpoint: 769,
                settings:{
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

  return (
    <section className="experience-container">
        <h5 id="experience">Work Experience</h5>
        <div className="experience-content">
            <div className="arrow-right" onClick={slideRight}>
                <span class="material-symbols-outlined">chevron_right</span>
            </div>
        </div>
        <div className="experience-content">
            <div className="arrow-left" onClick={slideLeft}>
                <span class="material-symbols-outlined">chevron_left</span>
            </div>
        </div>
        <div className="experience-content">
            <Slider ref={slideRef} {...setting}>
            {
                WORK_EXPERIENCE.map((item)=>(
                    <ExperienceCard key={item.title} details={item}/>
                ))
            }
            </Slider>
        </div>
    </section>
  )
}

export default WorkExperience