import frontend from "../../images/frontend.webp";
import backend from "../../images/backend.webp";
import toolsIcons from "../../images/tools-icon.png";
import softSkills from "../../images/software.png";
export const SKILLS = [
  {
    title: "Frontend",
    icon: frontend,
    skills: [
      { skill: "HTML5", percentage: "80%" },
      { skill: "Css", percentage: "90%" },
      { skill: "Bootstrap", percentage: "85%" },
      { skill: "JavaScript", percentage: "75%" },
      { skill: "React.js", percentage: "80%" },
      { skill: "Vue.js", percentage: "85%" },
    ],
  },
  {
    title: "Backend",
    icon: backend,
    skills: [
      { skill: "PHP", percentage: "80%" },
      { skill: "C#", percentage: "78%" },
      { skill: "Python", percentage: "70%" },
      { skill: "Node.js", percentage: "75%" },
    ],
  },
  {
    title: "Tools",
    icon: toolsIcons,
    skills: [
      { skill: "Github", percentage: "80%" },
      { skill: "Visual Studio Code", percentage: "90%" },
      { skill: "Visual Studio", percentage: "70%" },
      { skill: "Microsoft Office", percentage: "80%" },
      { skill: "Pycharm", percentage: "60%" },
    ],
  },
  {
    title: "Soft Skills",
    icon: softSkills,
    skills: [
      { skill: "Collaborator", percentage: "80%" },
      { skill: "Fast Typer", percentage: "90%" },
      { skill: "Attention to Detail", percentage: "70%" },
    ],
  },
  {
    title: "Mobile App Developement",
    icon: softSkills,
    skills: [
      { skill: "Flutter", percentage: "70%" },
      { skill: "React Native", percentage: "75%" },
    ],
  },
  {
    title: "Database",
    icon: softSkills,
    skills: [
      { skill: "MYSQL", percentage: "80%" },
      { skill: "Firebase", percentage: "60%" },
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    title: "Lead Software Developer at Ndola Vast Technologies",
    date: "September 2024 - Present",
    responsibilities: [
      "Lead and Provide Guidance Software Development Team",
      "Develop Frontend for web and mobile applications",
      "Develop Backend logic sending and recieving data from database",
      "Test all software solutions",
      "Bug fixing",
    ],
  },
  {
    title: "Software Developer at Ellisons Solutions",
    date: "October 2023 - September 2024",
    responsibilities: [
      "Develop Frontend for web and mobile applications",
      "Develop Backend logic sending and recieving data from database",
      "Test all software solutions",
      "Bug fixing",
    ],
  },
  {
    title: "Software Developer Associate at Northrise Software",
    date: "May 2022 - April 2023",
    responsibilities: [
      "Develop Frontend logic in Vue js for Zumasys Rover Web application",
      "Writing unit tests in JEST for Zumasys Rover web application",
      "Test all logic software solutions",
      "Bug fixing",
    ],
  },
  {
    title: "Software Developer at Grace Ministries Worship Centre",
    date: "August 2021 - Present",
    responsibilities: [
      "Develop Frontend for web and mobile applications",
      "Develop Backend logic sending and recieving data from database",
      "Test all software solutions",
      "Bug fixing",
    ],
  },
];
